<template>
  <div class="income-and-expenses">
    <Header v-bind:type="type" />
    <Breadcrumb_personalFi />
    <MainId />
    <div class="ll-body container" role="main">
      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title"><span class="bold-title">Personal Financial Statement</span> as of <span id="current_date"></span>
          </div>

          <h1 class="section-title">Annual Income & Expenses</h1>
          <span>(Alimony, Child Support or Separate Maintenance need not be disclosed unless you rely on it)</span>
          <div class="tf-note">
            Required fields are indicated by an asterisk (<span class="fhb-red"><strong>*</strong></span>)
          </div>
          <div class="v-spacer-30" />

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img class="page-error-icon"
                srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg.png" alt="" />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />
          <table class="income-expenses">
            <tr>
              <th class="bottom-border" scope="col" colspan="2">Income</th>
              <th class="bottom-border" scope="col" colspan="2">Expenses</th>
            </tr>
            <tr>
              <td><label for="income-salary">Salary from</label></td>
              <td class="right-border">
                <table class="with-desc">
                  <tr>
                    <td>
                      <label for="income-salary-desc" class="sr-only">Salary Source</label>
                      <input class="ll-text-spacing ll-text-desc ll-text tf-fname" 
                        placeholder="Description" id="income-salary-desc" name="description"
                        data-test="business-fname" type="text" v-model="income_salary_desc">
                    </td>
                    <td>
                      <money-input class="ll-textfield ll-textfield-money" id="income-salary" aria-describedby="income-salary-error" name="monetary-value"
                        v-model="income_salary"
                        :class="invalidIncomeSalary ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                        v-on:blur="validateIncomeSalary" data-test="financial-monthly-mortgage" />
                      
                      <div id="income-salary-error" class="ind-err-container">
                        <div v-if="invalidIncomeSalary" class="form-error-msg"
                          data-test="financial-monthly-mortgage-error">
                          <img class="form-error-icon"
                            srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                            src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                          <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>

              <td><label for="expense-residence">Mortgage - Residence</label></td>
              <td>
                <money-input class="ll-textfield ll-textfield-money" id="expense-residence" name="monetary-value" aria-describedby="expense-residence-error" 
                  v-model="expense_mortgage_residence"
                  :class="invalidExpenseMortgageResidence ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                  v-on:blur="validateExpenseMortgageResidence"
                  data-test="financial-monthly-mortgage" />

                <div id="expense-residence-error" class="ind-err-container">
                  <div v-if="invalidExpenseMortgageResidence" class="form-error-msg"
                    data-test="financial-monthly-mortgage-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="income-interest">Income from Interest</label></td>
              <td class="right-border">
                <money-input class="ll-textfield ll-textfield-money" id="income-interest" aria-describedby="income-interest-error" name="monetary-value"
                  v-model="income_interest"
                  :class="invalidIncomeInterest ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                  v-on:blur="validateIncomeInterest" data-test="financial-monthly-mortgage" />

                <div id="income-interest-error" class="ind-err-container">
                  <div v-if="invalidIncomeInterest" class="form-error-msg"
                    data-test="financial-monthly-mortgage-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                  </div>
                </div>
              </td>

              <td><label for="expense-mortgage-other">Mortgage - Other</label></td>
              <td>
                <money-input class="ll-textfield ll-textfield-money" id="expense-mortgage-other" aria-describedby="expense-mortgage-other-error" name="monetary-value"
                  v-model="expense_mortgage_other"
                  :class="invalidExpenseMortgageOther ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                  v-on:blur="validateExpenseMortgageOther" data-test="financial-monthly-mortgage" />

                <div id="expense-mortgage-other-error" class="ind-err-container">
                  <div v-if="invalidExpenseMortgageOther" class="form-error-msg"
                    data-test="financial-monthly-mortgage-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="income-securities">Income from Securities</label></td>
              <td class="right-border">
                <money-input class="ll-textfield ll-textfield-money" id="income-securities" aria-describedby="income-securities-error" name="monetary-value"
                  v-model="income_securities"
                  :class="invalidIncomeSecurities ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                  v-on:blur="validateIncomeSecurities" data-test="financial-monthly-mortgage" />

                <div id="income-securities-error" class="ind-err-container">
                  <div v-if="invalidIncomeSecurities" class="form-error-msg"
                    data-test="financial-monthly-mortgage-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                  </div>
                </div>
              </td>

              <td><label for="expense-installment">Installment Loans</label></td>
              <td>
                <money-input class="ll-textfield ll-textfield-money" id="expense-installment" aria-describedby="expense-installment-error" name="monetary-value"
                  v-model="expense_installment"
                  :class="invalidExpenseInstallment ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                  v-on:blur="validateExpenseInstallment" data-test="financial-monthly-mortgage" />

                <div id="expense-installment-error" class="ind-err-container">
                  <div v-if="invalidExpenseInstallment" class="form-error-msg"
                    data-test="financial-monthly-mortgage-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="income-rental">Rental Income</label></td>
              <td class="right-border">
                <money-input class="ll-textfield tf-mortgage-rent ll-textfield-money" id="income-rental" aria-describedby="income-rental-error" name="monetary-value"
                  v-model="income_rental"
                  :class="invalidIncomeRental ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                  v-on:blur="validateIncomeRental" data-test="financial-monthly-mortgage" />

                <div id="income-rental-error" class="ind-err-container">
                  <div v-if="invalidIncomeRental" class="form-error-msg"
                    data-test="financial-monthly-mortgage-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                  </div>
                </div>
              </td>

              <td><label for="expense-income-tax">Income Taxes</label></td>
              <td>
                <money-input class="ll-textfield tf-mortgage-rent ll-textfield-money"
                  id="expense-income-tax" aria-describedby="expense-income-tax-error" name="monetary-value" v-model="expense_income_tax"
                  :class="invalidExpenseIncomeTax ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                  v-on:blur="validateExpenseIncomeTax" data-test="financial-monthly-mortgage" />

                <div id="expense-income-tax-error" class="ind-err-container">
                  <div v-if="invalidExpenseIncomeTax" class="form-error-msg"
                    data-test="financial-monthly-mortgage-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><label for="income-net">Net Income from Business</label></td>
              <td class="right-border">
                <money-input class="ll-textfield tf-mortgage-rent ll-textfield-money" id="income-net" aria-describedby="income-net-error" name="monetary-value"
                  v-model="income_net_income"
                  :class="invalidIncomeNetIncome ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                  v-on:blur="validateIncomeNetIncome" data-test="financial-monthly-mortgage" />

                <div id="income-net-error" class="ind-err-container">
                  <div v-if="invalidIncomeNetIncome" class="form-error-msg"
                    data-test="financial-monthly-mortgage-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                  </div>
                </div>
              </td>

              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="bottom-border"><label for="assets-other-investments">Other Income</label></td>
              <td class="right-border bottom-border">
                <table class="with-desc">
                  <tr>
                    <td>
                      <label for="income-other" class="sr-only">Other Income Description</label>
                      <input class="ll-text-spacing ll-text-desc ll-text tf-fname" id="income-other" name="description"
                        placeholder="Description"
                        data-test="business-fname" type="text" v-model="income_other_desc">
                    </td>
                    <td>
                      <money-input class="ll-textfield ll-textfield-money" id="assets-other-investments" aria-describedby="assets-other-investments-error" name="monetary-value"
                        v-model="income_other"
                        :class="invalidIncomeOther ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                        v-on:blur="validateIncomeOther" data-test="financial-monthly-mortgage" />

                      <div id="assets-other-investments-error" class="ind-err-container">
                        <div v-if="invalidIncomeOther" class="form-error-msg"
                          data-test="financial-monthly-mortgage-error">
                          <img class="form-error-icon"
                            srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                            src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                          <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>

              <td class="bottom-border"><label for="expense-other">Other Expenses</label></td>
              <td class="bottom-border">
                <money-input class="ll-textfield tf-mortgage-rent ll-textfield-money" id="expense-other" aria-describedby="expense-other-error" name="monetary-value"
                  v-model="expense_other"
                  :class="invalidExpenseOther ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                  v-on:blur="validateExpenseOther" data-test="financial-monthly-mortgage" />

                <div id="expense-other-error" class="ind-err-container">
                  <div v-if="invalidExpenseOther" class="form-error-msg"
                    data-test="financial-monthly-mortgage-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="totals"><label for="income-total">Total Income</label></td>
              <td class="right-border">
                <money-input class="ll-textfield ll-textfield-money" id="income-total" aria-describedby="income-total-error" name="monetary-value"
									:disabled="true"
                  v-model="income_running_total"
                  :class="invalidIncomeTotal ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                  data-test="financial-monthly-mortgage" />

                <div id="income-total-error" class="ind-err-container">
                  <div v-if="invalidIncomeTotal" class="form-error-msg"
                    data-test="financial-monthly-mortgage-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                  </div>
                </div>
              </td>

              <td class="totals"><label for="expense-total">Total Expenses</label></td>
              <td>
                <money-input class="ll-textfield ll-textfield-money" id="expense-total" aria-describedby="expense-total-error" name="monetary-value"
									:disabled="true"
                  v-model="expense_running_total"
                  :class="invalidExpenseTotal ? 'll-textfield-error ll-textfield-money' : 'll-textfield ll-textfield-money'"
                  data-test="financial-monthly-mortgage" />

                <div id="expense-total-error" class="ind-err-container">
                  <div v-if="invalidExpenseTotal" class="form-error-msg"
                    data-test="financial-monthly-mortgage-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid dollar amount</div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <div class="v-spacer-60" />

          <!-- Association with FHB -->
          <div class="v-spacer-10" />
          <RadioInputs :inputValues="inputArr" 
            id="business-association-id" name="business-association-name" 
            v-model="businessAssociation" 
            legendText="Do you have any business association with an Executive or Director of FHB?"
            :missingSelection="missingAssociation"
          />
          <div class="ll-row row1-container yn-question">

            <!-- Association with FHB explanation -->
            <div class="ll-item yes-explain" v-if="businessAssociation == 'yes'" id="business-association-reason">
              <div class="question-label tf-label"><label for="bus-asso-desc">If yes, please explain<sup class="fhb-red">*</sup></label></div>
              <input maxlength="512" class="ll-text ll-textfield ll-explaination-textfield tf-fname" type="text" id="bus-asso-desc" aria-describedby="bus-asso-desc-error" name="description"
                v-model="businessAssociationReason"
                :class="missingBusinessAssociationReason ? 'll-textfield-error' : 'll-explaination-textfield'"
                v-on:blur="validateBusinessAssociationReason()" data-test="tf-loan-other" required>

              <div id="bus-asso-desc-error" class="ind-err-container">
                <div v-if="missingBusinessAssociationReason" class="form-error-msg">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please explain your association.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-40" />

          <!-- Executed a will -->
          <div class="v-spacer-10" />
          <RadioInputs :inputValues="inputArr" 
              id="executed-will-id" name="executed-will-name" 
              v-model="executedWill" 
              legendText="Do you have any business association with an Executive or Director of FHB?"
              :missingSelection="missingWill"
            />

          <div class="v-spacer-40" />

          <!-- Created a trust -->
          <div class="v-spacer-10" />
          <RadioInputs :inputValues="inputArr" 
              id="created-trust-id" name="ecreated-trust-name" 
              v-model="trust" 
              legendText="Have you created a Trust?"
              :missingSelection="missingTrust"
            />
          <div class="v-spacer-40" />
          <!-- Assets tied to Trust -->
          <RadioInputs :inputValues="inputArr" 
            id="assets-tied-to-trust-id" name="assets-tied-to-trust-name" 
            v-model="assetTrust" 
            legendText="Are any of the listed assets assigned to a Trust?"
            :missingSelection="missingAssetTrust"
          />
          <div class="v-spacer-10" />
          <div class="ll-row row1-container yn-question">
            <!-- Titled trust -->
            <div class="ll-item yes-explain" v-if="assetTrust == 'yes'" id="asset-trust-title">
              <div class="question-label tf-label"><label for="trust-title">If so, how is this Trust titled?<sup class="fhb-red">*</sup></label></div>
              <input maxlength="512" class="ll-text ll-textfield ll-explaination-textfield tf-fname" type="text" id="trust-title" aria-describedby="trust-title-error" name="description"
                v-model="assetTrustTitle"
                :class="missingAssetTrustTitle ? 'll-textfield-error' : 'll-explaination-textfield'"
                v-on:blur="validateAssetTrustTitle()" data-test="tf-loan-other" required>

              <div id="trust-title-error" class="ind-err-container">
                <div v-if="missingAssetTrustTitle" class="form-error-msg">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter the title of Trust.</div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="v-spacer-40" />
          <!-- Contingent liabilities -->
          <RadioInputs :inputValues="inputArr" 
            id="contingent-liabilities-id" name="contingent-liabilities-name" 
            v-model="contingentLiabilities" 
            legendText="Do you have any contingent liabilities for guarantees, endorsements or otherwise?"
            :missingSelection="missingContingentLiabilities"
          />
          <div class="ll-row row1-container yn-question">

            <!-- contingent liabilities explaination  -->
            <div class="ll-item yes-explain" v-if="contingentLiabilities == 'yes'" id="contingent-liabilities-reason">
              <div class="question-label tf-label"><label for="cont-lia-desc">If so, please explain<sup class="fhb-red">*</sup></label></div>
              <input maxlength="512" class="ll-text ll-textfield ll-explaination-textfield tf-fname" type="text" id="cont-lia-desc" aria-describedby="cont-lia-desc-error" name="description"
                v-model="contingentLiabilitiesReason"
                :class="missingContingentLiabilitiesReason ? 'll-textfield-error' : 'll-explaination-textfield'"
                v-on:blur="validateContingentLiabilitiesReason()" data-test="tf-loan-other" required>
                
              <div id="cont-lia-desc-error" class="ind-err-container">
                <div v-if="missingContingentLiabilitiesReason" class="form-error-msg">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please provide an explanation.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-40" />

          <!-- Other banks -->
          <div class="v-spacer-10" />
          <RadioInputs :inputValues="inputArr" 
            id="other-banks-id" name="other-banks-name" 
            v-model="otherBank" 
            legendText="Do you do business with any other banks?"
            :missingSelection="missingOtherBank"
          />
          <div class="ll-row row1-container yn-question">

            <!-- nature of business explanation  -->
            <div class="ll-item yes-explain" v-if="otherBank == 'yes'" id="other-banks-nature">
              <div class="question-label tf-label">
                <label for="business-nature-id">If so, nature of business?
                  <sup class="fhb-red">*</sup>
                </label>
                </div>
              <DropdownSearch 
                v-model="otherBankNature"
                :missingSelection="missingOtherBankNature"
                id="other-business-nature-id"
                name="testname"
                :dropdownArray="business_nature"
                dataTest="ll-bc-business-nature"
                @selected="validateOtherBankNature"
                ariaDescribe="other-business-nature-id-error"
              />

              <div id="other-business-nature-id-error" class="ind-err-container">
                <div v-if="missingOtherBankNature" class="form-error-msg">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please explain the nature of business.</div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="v-spacer-40" />

          <!-- Suits, judgements, tax deficiencies, etc  -->
          <div class="ll-row row1-container yn-question">
            <RadioInputs :inputValues="inputArr" 
              id="other-claims-id" name="other-claims-name" 
              v-model="otherClaims" 
              legendText="Are there any suits, judgments, tax deficiencies, or other claims pending or in process
                against you?"
              :missingSelection="missingOtherClaims"
            />
            <!-- Suits, judgements, tax deficiencies, etc explanation  -->
            <div class="ll-item yes-explain" v-if="otherClaims == 'yes'" id="other-claims-reason">
              <div class="question-label tf-label"><label for="suits-desc">If so, please explain<sup class="fhb-red">*</sup></label></div>
              <input maxlength="512" class="ll-text ll-textfield ll-explaination-textfield tf-fname" type="text" id="suits-desc" aria-describedby="suits-desc-error" name="monetary-value"
                v-model="otherClaimsReason"
                :class="missingOtherClaimsReason ? 'll-textfield-error' : 'll-explaination-textfield'"
                v-on:blur="validateOtherClaimsReason()" data-test="tf-loan-other" required>

              <div id="suits-desc-error" class="ind-err-container">
                <div v-if="missingOtherClaimsReason" class="form-error-msg">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please explain your other claims.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-40" />

          <!-- Bankruptcy  -->
          <div class="v-spacer-10" />
          <RadioInputs :inputValues="inputArr" 
              id="bankruptcy-id" name="bankruptcy-name" 
              v-model="bankruptcy" 
              legendText="Have you ever gone through bankruptcy or compromised a debt?"
              :missingSelection="missingBankruptcy"
            />
          <div class="ll-row row1-container yn-question">
            <div class="question-label tf-label" id="bankruptcy">
              Have you ever gone through bankruptcy or compromised a debt?<sup class="fhb-red">*</sup>
            </div>
            <!-- Bankruptcy explanation  -->
            <div class="ll-item yes-explain" v-if="bankruptcy == 'yes'" id="bankruptcy-reason">
              <div class="question-label tf-label"><label for="bankruptcy-desc">If so, please explain<sup class="fhb-red">*</sup></label></div>
              <input maxlength="512" class="ll-text ll-textfield ll-explaination-textfield tf-fname" type="text" id="bankruptcy-desc" aria-describedby="bankruptcy-desc-error" name="monetary-value"
                v-model="bankruptcyReason"
                :class="missingBankruptcyReason ? 'll-textfield-error' : 'll-explaination-textfield'"
                v-on:blur="validateBankruptcyReason()" data-test="tf-loan-other" required>

              <div id="bankruptcy-desc-error" class="ind-err-container">
                <div v-if="missingBankruptcyReason" class="form-error-msg">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please explain the bankruptcy or compromised debt.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-60" />

          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img class="form-error-icon sys-err-icon"
                srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg.png" alt="" />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : {{ item.error_value }}
              </li>
            </ul>
          </div>
          <div class="v-spacer-60" />


          <!-- continue button -->
          <div class="btn-container">
            <button type="button" class="back-btn" @click="handleBack()" data-test="nav-back">
              <img class="back-icon"
                srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png" alt="" />
              <span class="sr-only">Back</span>
            </button>
            <button class="continue-btn" id="ll-continue-btn" type="button" @click="handleContinue()"
              data-test="nav-continue">
              <span class="continue-text">Continue</span>
            </button>
          </div>
          <div class="v-spacer-40" />
          
        </section>
      </form>
    </div>
    <Footer />
  </div>

</template>

<script>
import Api from '@/app/ll-commercial-api'
import Header from '@/components/Header'
import DropdownSearch from '@/components/CustomInputs/DropdownSearch.vue'
import Constants from '@/app/business_constants'
import Validator from '@/app/validation'
import MainId from '@/components/MainId'
import Footer from '@/components/Footer'
import Breadcrumb_personalFi from '@/views/business/personal_fi_statement/Breadcrumb_personalFi.vue'
import jQuery from 'jquery'
import { mapActions, mapGetters } from 'vuex'
import Utils from '@/app/utils'
import MoneyInput from '@/components/CustomInputs/MoneyInput'
import RadioInputs from '../../../components/CustomInputs/RadioInputs.vue';

export default {
  name: 'Income And Expense Page',
  components: {
    Header,
    MainId,
    Footer,
    MoneyInput,
    Breadcrumb_personalFi,
    DropdownSearch,
    RadioInputs
  },
  data() {
    return {
      business_nature: Constants.NatureOfBusiness6,
      systemErrorMsg: '',

      errCnt: 0,

      missingAssociation: false,
      AssociationIndicator: false,
      missingBusinessAssociationReason: false,

      missingWill: false,
      WillIndicator: false,

      missingTrust: false,
      TrustIndicator: false,

      missingAssetTrust: false,
      AssetTrustIndicator: false,
      missingAssetTrustTitle: false,

      missingContingentLiabilities: false,
      ContingentLiabilitiesIndicator: false,
      missingContingentLiabilitiesReason: false,

      missingOtherBank: false,
      OtherBankIndicator: false,
      missingOtherBankNature: false,

      missingOtherClaims: false,
      OtherClaimsIndicator: false,
      missingOtherClaimsReason: false,

      missingBankruptcy: false,
      BankruptcyIndicator: false,
      missingBankruptcyReason: false,


      invalidIncomeSalary: false,

      invalidExpenseMortgageResidence: false,

      invalidExpenseMortgageOther: false,

      invalidIncomeInterest: false,

      invalidExpenseInstallment: false,

      invalidIncomeSecurities: false,

      invalidExpenseIncomeTax: false,

      invalidIncomeRental: false,

      invalidExpenseOther: false,

      invalidIncomeNetIncome: false,

			missingIncomeOther: false,
      invalidIncomeOther: false,
      missingIncomeOtherDesc: false,

      invalidIncomeTotal: false,

      invalidExpenseTotal: false,inputArr: Constants.radioInputValues

    }
  },
  created() {
    this.setCurrentPage(3);
    console.log('business', this.business_nature)
  },
  computed: {
    ...mapGetters("personalFiStatement",
      ['currentPage', 'type', 'application_id', 'jwt_token']
    ),
    businessAssociation: {
      get() { return this.$store.state.personalFiStatement.business_association },
      set(value) { this.$store.commit('personalFiStatement/setBusinessAssociation', value) }
    },
    businessAssociationIndicator: {
      get() { return this.$store.state.personalFiStatement.business_association_indicator },
      set(value) { this.$store.commit('personalFiStatement/setBusinessAssociationIndicator', value) }
    },
    businessAssociationReason: {
      get() { return this.$store.state.personalFiStatement.business_association_reason },
      set(value) { this.$store.commit('personalFiStatement/setBusinessAssociationReason', value) }
    },
    executedWill: {
      get() { return this.$store.state.personalFiStatement.executed_will },
      set(value) { this.$store.commit('personalFiStatement/setExecutedWill', value) }
    },
    executedWillIndicator: {
      get() { return this.$store.state.personalFiStatement.executed_will_indicator },
      set(value) { this.$store.commit('personalFiStatement/setExecutedWillIndicator', value) }
    },
    trust: {
      get() { return this.$store.state.personalFiStatement.trust },
      set(value) { this.$store.commit('personalFiStatement/setTrust', value) }
    },
    trustIndicator: {
      get() { return this.$store.state.personalFiStatement.trust_indicator },
      set(value) { this.$store.commit('personalFiStatement/setTrustIndicator', value) }
    },
    assetTrust: {
      get() { return this.$store.state.personalFiStatement.asset_trust },
      set(value) { this.$store.commit('personalFiStatement/setAssetTrust', value) }
    },
    assetTrustIndicator: {
      get() { return this.$store.state.personalFiStatement.asset_trust_indicator },
      set(value) { this.$store.commit('personalFiStatement/setAssetTrustIndicator', value) }
    },
    assetTrustTitle: {
      get() { return this.$store.state.personalFiStatement.asset_trust_title },
      set(value) { this.$store.commit('personalFiStatement/setAssetTrustTitle', value) }
    },
    contingentLiabilities: {
      get() { return this.$store.state.personalFiStatement.contingent_liabilities },
      set(value) { this.$store.commit('personalFiStatement/setContingentLiabilities', value) }
    },
    contingentLiabilitiesIndicator: {
      get() { return this.$store.state.personalFiStatement.contingent_liabilities_indicator },
      set(value) { this.$store.commit('personalFiStatement/setContingentLiabilitiesIndicator', value) }
    },
    contingentLiabilitiesReason: {
      get() { return this.$store.state.personalFiStatement.contingent_liabilities_reason },
      set(value) { this.$store.commit('personalFiStatement/setContingentLiabilitiesReason', value) }
    },
    otherBank: {
      get() { return this.$store.state.personalFiStatement.other_bank },
      set(value) { this.$store.commit('personalFiStatement/setOtherBank', value) }
    },
    otherBankIndicator: {
      get() { return this.$store.state.personalFiStatement.other_bank_indicator },
      set(value) { this.$store.commit('personalFiStatement/setOtherBankIndicator', value) }
    },
    otherBankNature: {
      get() { return this.$store.state.personalFiStatement.other_bank_nature },
      set(value) { 
        this.$store.commit('personalFiStatement/setOtherBankNature', value) 
        this.validateOtherBankNature() }
    },
    otherClaims: {
      get() { return this.$store.state.personalFiStatement.other_claims },
      set(value) { this.$store.commit('personalFiStatement/setOtherClaims', value) }
    },
    otherClaimsIndicator: {
      get() { return this.$store.state.personalFiStatement.other_claims_indicator },
      set(value) { this.$store.commit('personalFiStatement/setOtherClaimsIndicator', value) }
    },
    otherClaimsReason: {
      get() { return this.$store.state.personalFiStatement.other_claims_reason },
      set(value) { this.$store.commit('personalFiStatement/setOtherClaimsReason', value) }
    },
    bankruptcy: {
      get() { return this.$store.state.personalFiStatement.bankruptcy },
      set(value) { this.$store.commit('personalFiStatement/setBankruptcy', value) }
    },
    bankruptcyIndicator: {
      get() { return this.$store.state.personalFiStatement.bankruptcy_indicator },
      set(value) { this.$store.commit('personalFiStatement/setBankruptcyIndicator', value) }
    },
    bankruptcyReason: {
      get() { return this.$store.state.personalFiStatement.bankruptcy_reason },
      set(value) { this.$store.commit('personalFiStatement/setBankruptcyReason', value) }
    },
    expense_mortgage_residence: {
			get() { return this.$store.state.personalFiStatement.expense.mortgage_residence },
      set(value) { this.$store.commit('personalFiStatement/setExpenseMortgageResidence', value) }
    },
    expense_mortgage_other: {
			get() { return this.$store.state.personalFiStatement.expense.mortgage_other },
      set(value) { this.$store.commit('personalFiStatement/setExpenseMortgageOther', value) }
    },
		expense_installment: {
			get() { return this.$store.state.personalFiStatement.expense.installment },
			set(value) { this.$store.commit('personalFiStatement/setExpenseInstallment', value) }
		},
		expense_other: {
			get() { return this.$store.state.personalFiStatement.expense.other },
			set(value) { this.$store.commit('personalFiStatement/setExpenseOther', value) }
		},
		expense_income_tax: {
			get() { return this.$store.state.personalFiStatement.expense.income_tax },
			set(value) { this.$store.commit('personalFiStatement/setExpenseIncomeTax', value) }
		},
		expense_running_total: {
			get () { return this.sumOfExpenses() }
		},
		expense_total: {
      get() { return this.$store.state.personalFiStatement.expense.total },
      set(value) { this.$store.commit('personalFiStatement/setExpenseTotal', value) }
    },
		income_salary_desc: {
			get() { return this.$store.state.personalFiStatement.income.salary_desc },
			set(value) { this.$store.commit('personalFiStatement/setIncomeSalaryDesc', value) }
		},
		income_salary: {
			get() { return this.$store.state.personalFiStatement.income.salary },
			set(value) { this.$store.commit('personalFiStatement/setIncomeSalary', value) }
		},
    income_interest: {
      get() { return this.$store.state.personalFiStatement.income.interest },
      set(value) { this.$store.commit('personalFiStatement/setIncomeInterest', value) }
    },
    income_securities: {
      get() { return this.$store.state.personalFiStatement.income.securities },
      set(value) { this.$store.commit('personalFiStatement/setIncomeSecurities', value) }
    },
    income_rental: {
      get() { return this.$store.state.personalFiStatement.income.rental },
      set(value) { this.$store.commit('personalFiStatement/setIncomeRental', value) }
    },
    income_net_income: {
      get() { return this.$store.state.personalFiStatement.income.net_income },
      set(value) { this.$store.commit('personalFiStatement/setIncomeNetIncome', value) }
    },
    income_other: {
      get() { return this.$store.state.personalFiStatement.income.other },
      set(value) { this.$store.commit('personalFiStatement/setIncomeOther', value) }
    },
    income_other_desc: {
      get() { return this.$store.state.personalFiStatement.income.other_desc },
      set(value) { this.$store.commit('personalFiStatement/setIncomeOtherDesc', value) }
    },
		income_running_total: {
			get () { return this.sumOfIncome() }
		},
    income_total: {
      get() { return this.$store.state.personalFiStatement.income.total },
      set(value) { this.$store.commit('personalFiStatement/setIncomeTotal', value) }
    },
  },
  mounted() {
    this.setType('commercial-loan')
    jQuery("html, body").animate({ scrollTop: 0 });

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    document.getElementById("current_date").innerHTML = month + "/" + day + "/" + year;

  },
  methods: {
    ...mapActions("personalFiStatement",
      ['setBusinessAssociationReason', 'setExecutedWillIndicator', 'setExecutedWill', 'setBusinessAssociation', 'setBusinessAssociationIndicator', 'setTrust', 'setTrustIndicator', 'setCurrentPage', 'setType', 'setOtherClaims', 'setOtherClaimsIndicator', 'setOtherClaimsReason', 'setBankruptcy', 'setBankruptcyIndicator', 'setBankruptcyReason',
        'setAssetTrust', 'setAssetTrustIndicator', 'setAssetTrustTitle', 'setContingentLiabilities', 'setContingentLiabilitiesIndicator', 'setContingentLiabilitiesReason', 'setOtherBank', 'setOtherBankIndicator', 'setOtherBankNature', 'setValidatedNavPage', 'setIncomeSalary', 'setIncomeSalaryDesc', 'setExpenseMortgageResidence',
        'setExpenseMortgageOther', 'setIncomeInterest', 'setExpenseInstallment', 'setIncomeSecurities', 'setExpenseIncomeTax', 'setIncomeRental', 'setExpenseOther', 'setIncomeNetIncome', 'setIncomeOtherDesc', 'setIncomeOther', 'setIncomeTotal', 'setExpenseTotal',
        'setDocumentUrls', 'setAdditionalDocumentUrls']
    ),
		formatMoneyFromString(mon) { 
      if (mon == "") { 
        return 0
      }
      let str = mon.replace("$", "").replaceAll(" ", "").replaceAll(",", "")
      let fl = parseFloat(str)
      let ret = +fl
      return ret
    },
		sumOfIncome() {
			let tot = this.formatMoneyFromString(this.income_salary) +
			this.formatMoneyFromString(this.income_interest) +
			this.formatMoneyFromString(this.income_securities) +
			this.formatMoneyFromString(this.income_rental) +
			this.formatMoneyFromString(this.income_net_income) +
			this.formatMoneyFromString(this.income_other) 
			let ret = `$${tot}`
      this.income_total = ret
      return ret
		},
		sumOfExpenses() {
			let tot = this.formatMoneyFromString(this.expense_mortgage_residence) +
			this.formatMoneyFromString(this.expense_mortgage_other) +
			this.formatMoneyFromString(this.expense_installment) +
			this.formatMoneyFromString(this.expense_other) +
			this.formatMoneyFromString(this.expense_income_tax)
			let ret = `$${tot}`
      this.expense_total = ret
      return ret
		},
    async putPagePersonalFi(payload) {
      this.systemErrorMsg = '';
      this.systemErrorArray = [];

      const resp = await Api.putPagePersonalFi(this.application_id, payload, this.jwt_token);
      console.log('--- response ---');
      console.log(resp);

      if (resp && resp.data) {

        if (resp.data.status == "success") {

          // update breadcrumb state
          this.setValidatedNavPage({ name: 'personal', isValid: 1 });
          if (resp.data.response?.documents) {
            this.setDocumentUrls(resp.data.response.documents)
            this.setAdditionalDocumentUrls(resp.data.response.additional_documents)
            // sleep for a smooth transition
            await new Promise(r => setTimeout(r, 400));
  
            this.$router.push({ name: 'PersonalFi-Document-Upload' })
            return
          }
          this.$router.push({ name: 'Confirmation' })
          Utils.removeProcessingMask('#ll-continue-btn')
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message;
          }

          if (resp.data.response.application_errors && (resp.data.response.application_errors.length > 0)) {
            for (const x of resp.data.response.application_errors) {
              console.log(x.error_message);
              this.systemErrorArray.push(x)
            }
          }
          Utils.removeProcessingMask('#ll-continue-btn')
        }
      }
    },
    handleBack() {
      this.$router.push({ name: 'Assets And Liabilities' })
    },
    handleContinue() {

      Utils.addProcessingMask('#ll-continue-btn')


      this.validatePage();

      this.$nextTick(() => {
        this.errCnt = jQuery('.form-error-txt').length;

        if (!this.errCnt) {
          const payload =
          {
            "page": this.currentPage,
            "income_salary_from": this.income_salary_desc,
            "income_salary": this.income_salary ? parseFloat((this.income_salary).replaceAll(',', '').replaceAll('$', '')) : 0,
            "income_interest": this.income_interest ? parseFloat((this.income_interest).replaceAll(',', '').replaceAll('$', '')) : 0,
            "income_securities": this.income_securities ? parseFloat((this.income_securities).replaceAll(',', '').replaceAll('$', '')) : 0,
            "income_rental": this.income_rental ? parseFloat((this.income_rental).replaceAll(',', '').replaceAll('$', '')) : 0,
            "income_business": this.income_net_income ? parseFloat((this.income_net_income).replaceAll(',', '').replaceAll('$', '')) : 0,
            "income_other_from": this.income_other_desc,
            "income_other": this.income_other ? parseFloat((this.income_other).replaceAll(',', '').replaceAll('$', '')) : 0,
            "income_total": this.income_total ? parseFloat((this.income_total).replaceAll(',', '').replaceAll('$', '')) : 0,
            "expenses_mortgage_residence": this.expense_mortgage_residence ? parseFloat((this.expense_mortgage_residence).replaceAll(',', '').replaceAll('$', '')) : 0,
            "expenses_mortgage_other": this.expense_mortgage_other ? parseFloat((this.expense_mortgage_other).replaceAll(',', '').replaceAll('$', '')) : 0,
            "expenses_loans": this.expense_installment ? parseFloat((this.expense_installment).replaceAll(',', '').replaceAll('$', '')) : 0,
            "expenses_income_taxes": this.expense_income_tax ? parseFloat((this.expense_income_tax).replaceAll(',', '').replaceAll('$', '')) : 0,
            "expenses_other": this.expense_other ? parseFloat((this.expense_other).replaceAll(',', '').replaceAll('$', '')) : 0,
            "expenses_total": this.expense_total ? parseFloat((this.expense_total).replaceAll(',', '').replaceAll('$', '')) : 0,

            "business_association_indicator": this.businessAssociation === 'yes' ? true : false,
            "business_association_explanation": this.businessAssociationReason,
            "has_will": this.executedWill === 'yes' ? true : false,
            "has_trust": this.trust === 'yes' ? true : false,
            "has_trust_assigned_asset": this.assetTrust === 'yes' ? true : false,
            "has_trust_assigned_asset_title": this.assetTrustTitle,
            "has_contingent_liabilities": this.contingentLiabilities === 'yes' ? true : false,
            "has_contingent_liabilities_explanation": this.contingentLiabilitiesReason,
            "has_other_banks": this.otherBank === 'yes' ? true : false,
            "has_other_banks_explanation": this.otherBank === 'yes' ? this.otherBankNature.value : '',
            "has_claims": this.otherClaim === 'yes' ? true : false,
            "has_claims_explanation": this.otherClaimsReason,
            "has_compromised_debt": this.bankruptcy === 'yes' ? true : false,
            "has_compromised_debt_explanation": this.bankruptcyReason
          }

          this.putPagePersonalFi(payload);
        } else {
          this.setValidatedNavPage({ name: 'personal', isValid: 0 });

          if (this.errCnt > 1) {
            jQuery("html, body").animate({ scrollTop: jQuery("#commercial-page-errors").offset().top - 20 });
          } else {
            let offset = 0;


            if (this.missingIncomeSalary || this.invalidIncomeSalary) offset = jQuery("income-salary").offset().top

            if (this.missingExpenseMortgageResidence || this.invalidExpenseMortgageResidence) offset = jQuery("#expense-residence").offset().top
            if (this.missingExpenseMortgageOther || this.invalidExpenseOther) offset = jQuery("#expense-mortgage-other").offset().top
            if (this.missingIncomeInterest || this.invalidIncomeInterest) offset = jQuery("#income-interest").offset().top
            if (this.missingExpenseInstallment || this.invalidExpenseInstallment) offset = jQuery("#expense-installment").offset().top
            if (this.missingIncomeSecurities || this.invalidIncomeSecurities) offset = jQuery("#income-securities").offset().top
            if (this.missingExpenseIncomeTax || this.invalidExpenseIncomeTax) offset = jQuery("#expense-income-tax").offset().top
            if (this.missingIncomeRental || this.invalidIncomeRental) offset = jQuery("#income-rental").offset().top
            if (this.missingExpenseOther || this.invalidExpenseOther) offset = jQuery("#expense-other").offset().top
            if (this.missingIncomeNetIncome || this.invalidIncomeNetIncome) offset = jQuery("#income-net").offset().top
            if (this.missingIncomeOther || this.invalidIncomeOther) offset = jQuery("#income-other").offset().top
            if (this.missingIncomeTotal || this.invalidIncomeTotal) offset = jQuery("#income-total").offset().top
            if (this.missingExpenseTotal || this.invalidExpenseTotal) offset = jQuery("#expense-total").offset().top
            if (this.missingAssociation) offset = jQuery("#business-association").offset().top
            if (this.missingBusinessAssociationReason) offset = jQuery("#business-association-reason").offset().top
            if (this.missingWill) offset = jQuery("#executed-will").offset().top
            if (this.missingTrust) offset = jQuery("#created-trust").offset().top
            if (this.missingAssetTrust) offset = jQuery("#trust-asset").offset().top
            if (this.missingAssetTrustTitle) offset = jQuery("#asset-trust-title").offset().top
            if (this.missingLifeInsLoan || this.invalidLifeInsLoan) offset = jQuery("#liabilities-life-insurance").offset().top
            if (this.missingContingentLiabilities) offset = jQuery("#contingent-liabilities").offset().top
            if (this.missingContingentLiabilitiesReason) offset = jQuery("#contingent-liabilities-reason").offset().top
            if (this.missingOtherBank) offset = jQuery("#other-banks").offset().top
            if (this.missingOtherBankNature) offset = jQuery("#other-banks-nature").offset().top
            if (this.missingOtherClaims) offset = jQuery("#other-claims").offset().top
            if (this.missingOtherClaimsReason) offset = jQuery("#other-claims-reason").offset().top
            if (this.missingBankruptcy) offset = jQuery("#bankruptcy").offset().top
            if (this.missingBankruptcyReason) offset = jQuery("#bankruptcy-reason").offset().top

            jQuery("html, body").animate({ scrollTop: offset });
          }
          // remove processing
          Utils.removeProcessingMask('#ll-continue-btn')
        }

      })

    },
    validatePage() {


      this.validateAssociation();
      if (this.businessAssociation == "yes") {
        this.validateBusinessAssociationReason();
      }

      this.validateWill();
      this.validateTrust();
      this.validateAssetTrust();
      if (this.assetTrust == "yes") {
        this.validateAssetTrustTitle();
      }

      this.validateContingentLiabilities();
      if (this.contingentLiabilities == "yes") {
        this.validateContingentLiabilitiesReason();
      }

      this.validateOtherBank();
      if (this.otherBank == "yes") {
        this.validateOtherBankNature();
      }

      this.validateOtherClaims();
      if (this.otherClaims == "yes") {
        this.validateOtherClaimsReason();
      }

      this.validateBankruptcy();
      if (this.bankruptcy == "yes") {
        this.validateBankruptcyReason();
      }

      this.validateIncomeSalary();
      this.validateExpenseMortgageResidence();
      this.validateExpenseMortgageOther();
      this.validateIncomeInterest();
      this.validateExpenseInstallment();
      this.validateIncomeSecurities();
      this.validateExpenseIncomeTax();
      this.validateIncomeRental();
      this.validateExpenseOther();
      this.validateIncomeNetIncome();
      this.validateIncomeOther();
      this.validateIncomeTotal();
      this.validateExpenseTotal();




    },
    validateIncomeSalary() {
      this.missingIncomeSalary = this.income_salary ? false : true;
      if (!this.missingIncomeSalary) {
        this.invalidIncomeSalary = Validator.isValidMoney(this.income_salary) ? false : true;
      }
    },
    validateExpenseMortgageResidence() {
      this.missingExpenseMortgageResidence = this.expense_mortgage_residence ? false : true;
      if (!this.missingExpenseMortgageResidence) {
        this.invalidExpenseMortgageResidence = Validator.isValidMoney(this.expense_mortgage_residence) ? false : true;
      }
    },
    validateExpenseMortgageOther() {
      this.missingExpenseMortgageOther = this.expense_mortgage_other ? false : true;
      if (!this.missingExpenseMortgageOther) {
        this.invalidExpenseMortgageOther = Validator.isValidMoney(this.expense_mortgage_other) ? false : true;
      }
    },
    validateIncomeInterest() {
      this.missingIncomeInterest = this.income_interest ? false : true;
      if (!this.missingIncomeInterest) {
        this.invalidIncomeInterest = Validator.isValidMoney(this.income_interest) ? false : true;
      }
    },
    validateExpenseInstallment() {
      this.missingExpenseInstallment = this.expense_installment ? false : true;
      if (!this.missingExpenseInstallment) {
        this.invalidExpenseInstallment = Validator.isValidMoney(this.expense_installment) ? false : true;
      }
    },
    validateIncomeSecurities() {
      this.missingIncomeSecurities = this.income_securities ? false : true;
      if (!this.missingIncomeSecurities) {
        this.invalidIncomeSecurities = Validator.isValidMoney(this.income_securities) ? false : true;
      }
    },
    validateExpenseIncomeTax() {
      this.missingExpenseIncomeTax = this.expense_income_tax ? false : true;
      if (!this.missingExpenseIncomeTax) {
        this.invalidExpenseIncomeTax = Validator.isValidMoney(this.expense_income_tax) ? false : true;
      }
    },
    validateIncomeRental() {
      this.missingIncomeRental = this.income_rental ? false : true;
      if (!this.missingIncomeRental) {
        this.invalidIncomeRental = Validator.isValidMoney(this.income_rental) ? false : true;
      }
    },
    validateExpenseOther() {
      this.missingExpenseOther = this.expense_other ? false : true;
      if (!this.missingExpenseOther) {
        this.invalidExpenseOther = Validator.isValidMoney(this.expense_other) ? false : true;
      }
    },
    validateIncomeNetIncome() {
      this.missingIncomeNetIncome = this.income_net_income ? false : true;
      if (!this.missingIncomeNetIncome) {
        this.invalidIncomeNetIncome = Validator.isValidMoney(this.income_net_income) ? false : true;
      }
    },
    validateIncomeOther() {
      this.missingIncomeOther = this.income_other ? false : true;
      if (!this.missingIncomeOther) {
        this.invalidIncomeOther = Validator.isValidMoney(this.income_other) ? false : true;
      }
    },
    validateIncomeTotal() {
      this.missingIncomeTotal = this.income_total ? false : true;
      if (!this.missingIncomeTotal) {
        this.invalidIncomeTotal = Validator.isValidMoney(this.income_total) ? false : true;
      }
    },
    validateExpenseTotal() {
      this.missingExpenseTotal = this.expense_total ? false : true;
      if (!this.missingExpenseTotal) {
        this.invalidExpenseTotal = Validator.isValidMoney(this.expense_total) ? false : true;
      }
    },
    validateAssetsOther() {
      this.missingAssetsOther = this.assets_other ? false : true;
      if (!this.missingAssetsOther) {
        this.invalidAssetsOther = Validator.isValidMoney(this.assets_other) ? false : true;
      }
    },
    validateAssetsTotal() {
      this.missingAssetsTotal = this.assets_total ? false : true;
      if (!this.missingAssetsTotal) {
        this.invalidAssetsTotal = Validator.isValidMoney(this.assets_total) ? false : true;
      }
    },
    validateLoansFhb() {
      this.missingLoansFhb = this.liabilities_loans_fhb ? false : true;
      if (!this.missingLoansFhb) {
        this.invalidLoansFhb = Validator.isValidMoney(this.liabilities_loans_fhb) ? false : true;
      }
    },
    validateLoansOther() {
      this.missingLoansOther = this.liabilities_loans_other ? false : true;
      if (!this.missingLoansOther) {
        this.invalidLoansOther = Validator.isValidMoney(this.liabilities_loans_other) ? false : true;
      }
    },
    validateChargeCards() {
      this.missingChargeCards = this.liabilities_charge_cards ? false : true;
      if (!this.missingChargeCards) {
        this.invalidChargeCards = Validator.isValidMoney(this.liabilities_charge_cards) ? false : true;
      }
    },
    validateAccPayable() {
      this.missingAccPayable = this.liabilities_acc_payable ? false : true;
      if (!this.missingAccPayable) {
        this.invalidAccPayable = Validator.isValidMoney(this.liabilities_acc_payable) ? false : true;
      }
    },
    validateLiabilitiesDueFriends() {
      this.missingLiabilitiesDueFriends = this.liabilities_due_friends ? false : true;
      if (!this.missingLiabilitiesDueFriends) {
        this.invalidLiabilitiesDueFriends = Validator.isValidMoney(this.liabilities_due_friends) ? false : true;
      }
    },
    validateNotesPayable() {
      this.missingNotesPayable = this.liabilities_notes_payable ? false : true;
      if (!this.missingNotesPayable) {
        this.invalidNotesPayable = Validator.isValidMoney(this.liabilities_notes_payable) ? false : true;
      }
    },
    validateMortgage() {
      this.missingMortgage = this.liabilities_mortgages ? false : true;
      if (!this.missingMortgage) {
        this.invalidMortgage = Validator.isValidMoney(this.liabilities_mortgages) ? false : true;
      }
    },
    validateIncomeTax() {
      this.missingIncomeTax = this.liabilities_income_taxes ? false : true;
      if (!this.missingIncomeTax) {
        this.invalidIncomeTax = Validator.isValidMoney(this.liabilities_income_taxes) ? false : true;
      }
    },
    validateOtherTax() {
      this.missingOtherTax = this.liabilities_other_taxes ? false : true;
      if (!this.missingOtherTax) {
        this.invalidOtherTax = Validator.isValidMoney(this.liabilities_other_taxes) ? false : true;
      }
    },
    validateLifeInsLoan() {
      this.missingLifeInsLoan = this.liabilities_life_insurance ? false : true;
      if (!this.missingLifeInsLoan) {
        this.invalidLifeInsLoan = Validator.isValidMoney(this.liabilities_life_insurance) ? false : true;
      }
    },
    validateLiabilitiesOther() {
      this.missingLiabilitiesOther = this.liabilities_other ? false : true;
      if (!this.missingLiabilitiesOther) {
        this.invalidLiabilitiesOther = Validator.isValidMoney(this.liabilities_other) ? false : true;
      }
    },
    validateAutoLoan() {
      this.missingAutoLoan = this.liabilities_auto ? false : true;
      if (!this.missingAutoLoan) {
        this.invalidAutoLoan = Validator.isValidMoney(this.liabilities_auto) ? false : true;
      }
    },
    validateLiabilitiesTotal() {
      this.missingLiabilitiesTotal = this.liabilities_total ? false : true;
      if (!this.missingLiabilitiesTotal) {
        this.invalidLiabilitiesTotal = Validator.isValidMoney(this.liabilities_total) ? false : true;
      }
    },
    validateNetWorth() {
      this.missingNetWorth = this.liabilities_net_worth ? false : true;
      if (!this.missingNetWorth) {
        this.invalidNetWorth = Validator.isValidMoney(this.liabilities_net_worth) ? false : true;
      }
    },
    validateLiabilitiesTotalLoan() {
      this.missingLiabilitiesTotalLoan = this.liabilities_total_loan ? false : true;
      if (!this.missingLiabilitiesTotalLoan) {
        this.invalidLiabilitiesTotalLoan = Validator.isValidMoney(this.liabilities_total_loan) ? false : true;
      }
    },
    gotoFHB() {
      // form validation
      window.location.replace('https://fhb.com');
    },

    toggleAssociation(val) {
      this.setBusinessAssociation(val)
      if (val == "yes") {
        this.AssociationIndicator = true,
          this.$store.commit('personalFiStatement/setBusinessAssociationIndicator', true)
      } else if (val == "no") {
        this.AssociationIndicator = false,
          this.$store.commit('personalFiStatement/setBusinessAssociationIndicator', false)
      }
      this.validateAssociation()
    },
    validateAssociation() {
      if (this.businessAssociation == "yes") {
          this.AssociationIndicator = true
          this.$store.commit('personalFiStatement/setBusinessAssociationIndicator', true)
      } else if (this.businessAssociation == "no") {
          this.AssociationIndicator = false
          this.$store.commit('personalFiStatement/setBusinessAssociationIndicator', false)
      }
      if (this.missingAssociation && this.businessAssociation) {
        this.missingAssociation = false;
      } else if (!this.missingAssociation && !this.businessAssociation) {
        this.missingAssociation = true;
      }
    },
    validateBusinessAssociationReason() {
      this.missingBusinessAssociationReason = this.businessAssociationReason ? false : true;
    },
    toggleWill(val) {
      this.setExecutedWill(val)
      if (val == "yes") {
        this.WillIndicator = true,
          this.$store.commit('personalFiStatement/setExecutedWillIndicator', true)
      } else if (val == "no") {
        this.WillIndicator = false,
          this.$store.commit('personalFiStatement/setExecutedWillIndicator', false)
      }
      this.validateWill()
    },
    validateWill() {
      if (this.executedWill == "yes") {
        this.WillIndicator = true,
          this.$store.commit('personalFiStatement/setExecutedWillIndicator', true)
      } else if (this.executedWill == "no") {
        this.WillIndicator = false,
          this.$store.commit('personalFiStatement/setExecutedWillIndicator', false)
      }
      if (this.missingWill && this.executedWill) {
        this.missingWill = false;
      } else if (!this.missingWill && !this.executedWill) {
        this.missingWill = true;
      }
    },
    toggleTrust(val) {
      this.setTrust(val)
      if (val == "yes") {
        this.TrustIndicator = true,
          this.$store.commit('personalFiStatement/setTrustIndicator', true)
      } else if (val == "no") {
        this.TrustIndicator = false,
          this.$store.commit('personalFiStatement/setTrustIndicator', false)
      }
      this.validateTrust()
    },
    validateTrust() {
      if (this.trust == "yes") {
        this.TrustIndicator = true,
          this.$store.commit('personalFiStatement/setTrustIndicator', true)
      } else if (this.trust == "no") {
        this.TrustIndicator = false,
          this.$store.commit('personalFiStatement/setTrustIndicator', false)
      }
      if (this.missingTrust && this.trust) {
        this.missingTrust = false;
      } else if (!this.missingTrust && !this.trust) {
        this.missingTrust = true;
      }
    },

    toggleAssetTrust(val) {
      this.setAssetTrust(val)
      if (val == "yes") {
        this.AssetTrustIndicator = true,
          this.$store.commit('personalFiStatement/setAssetTrustIndicator', true)
      } else if (val == "no") {
        this.AssetTrustIndicator = false,
          this.$store.commit('personalFiStatement/setAssetTrustIndicator', false)
      }
      this.validateAssetTrust()
    },
    validateAssetTrust() {
      if (this.assetTrust == "yes") {
        this.AssetTrustIndicator = true,
          this.$store.commit('personalFiStatement/setAssetTrustIndicator', true)
      } else if (this.assetTrust == "no") {
        this.AssetTrustIndicator = false,
          this.$store.commit('personalFiStatement/setAssetTrustIndicator', false)
      }
      if (this.missingAssetTrust && this.assetTrust) {
        this.missingAssetTrust = false;
      } else if (!this.missingAssetTrust && !this.assetTrust) {
        this.missingAssetTrust = true;
      }
    },
    validateAssetTrustTitle() {
      this.missingAssetTrustTitle = this.assetTrustTitle ? false : true;
    },

    toggleLiabilities(val) {
      this.setContingentLiabilities(val)
      if (val == "yes") {
        this.ContingentLiabilitiesIndicator = true,
          this.$store.commit('personalFiStatement/setContingentLiabilitiesIndicator', true)
      } else if (val == "no") {
        this.ContingentLiabilitiesIndicator = false,
          this.$store.commit('personalFiStatement/setContingentLiabilitiesIndicator', false)
      }
      this.validateContingentLiabilities()
    },
    validateContingentLiabilities() {
      if (this.contingentLiabilities == "yes") {
        this.ContingentLiabilitiesIndicator = true,
          this.$store.commit('personalFiStatement/setContingentLiabilitiesIndicator', true)
      } else if (this.contingentLiabilities == "no") {
        this.ContingentLiabilitiesIndicator = false,
          this.$store.commit('personalFiStatement/setContingentLiabilitiesIndicator', false)
      }
      if (this.missingContingentLiabilities && this.contingentLiabilities) {
        this.missingContingentLiabilities = false;
      } else if (!this.missingContingentLiabilities && !this.contingentLiabilities) {
        this.missingContingentLiabilities = true;
      }
    },
    validateContingentLiabilitiesReason() {
      this.missingContingentLiabilitiesReason = this.contingentLiabilitiesReason ? false : true;
    },

    toggleOtherBank(val) {
      this.setOtherBank(val)
      if (val == "yes") {
        this.OtherBankIndicator = true,
          this.$store.commit('personalFiStatement/setOtherBankIndicator', true)
      } else if (val == "no") {
        this.OtherBankIndicator = false,
          this.$store.commit('personalFiStatement/setOtherBankIndicator', false)
      }
      this.validateOtherBank()
    },
    validateOtherBank() {
      if (this.otherBank == "yes") {
        this.OtherBankIndicator = true,
          this.$store.commit('personalFiStatement/setOtherBankIndicator', true)
      } else if (this.otherBank == "no") {
        this.OtherBankIndicator = false,
          this.$store.commit('personalFiStatement/setOtherBankIndicator', false)
      }
      if (this.missingOtherBank && this.otherBank) {
        this.missingOtherBank = false;
      } else if (!this.missingOtherBank && !this.otherBank) {
        this.missingOtherBank = true;
      }
    },
    validateOtherBankNature() {
      this.missingOtherBankNature = this.otherBankNature ? false : true;
    },

    toggleOtherClaims(val) {
      this.setOtherClaims(val)
      if (val == "yes") {
        this.OtherClaimsIndicator = true,
          this.$store.commit('personalFiStatement/setOtherClaimsIndicator', true)
      } else if (val == "no") {
        this.OtherClaimsIndicator = false,
          this.$store.commit('personalFiStatement/setOtherClaimsIndicator', false)
      }
      this.validateOtherClaims()
    },
    validateOtherClaims() {
      if (this.otherClaims == "yes") {
        this.OtherClaimsIndicator = true,
          this.$store.commit('personalFiStatement/setOtherClaimsIndicator', true)
      } else if (this.otherClaims == "no") {
        this.OtherClaimsIndicator = false,
          this.$store.commit('personalFiStatement/setOtherClaimsIndicator', false)
      }
      if (this.missingOtherClaims && this.otherClaims) {
        this.missingOtherClaims = false;
      } else if (!this.missingOtherClaims && !this.otherClaims) {
        this.missingOtherClaims = true;
      }
    },
    validateOtherClaimsReason() {
      this.missingOtherClaimsReason = this.otherClaimsReason ? false : true;
    },

    toggleBankruptcy(val) {
      this.setBankruptcy(val)
      if (val == "yes") {
        this.bankruptcyIndicator = true,
          this.$store.commit('personalFiStatement/setBankruptcyIndicator', true)
      } else if (val == "no") {
        this.bankruptcyIndicator = false,
          this.$store.commit('personalFiStatement/setBankruptcyIndicator', false)
      }
      this.validateBankruptcy()
    },
    validateBankruptcy() {
      if (this.bankruptcy == "yes") {
        this.bankruptcyIndicator = true,
          this.$store.commit('personalFiStatement/setBankruptcyIndicator', true)
      } else if (this.bankruptcy == "no") {
        this.bankruptcyIndicator = false,
          this.$store.commit('personalFiStatement/setBankruptcyIndicator', false)
      }
      if (this.missingBankruptcy && this.bankruptcy) {
        this.missingBankruptcy = false;
      } else if (!this.missingBankruptcy && !this.bankruptcy) {
        this.missingBankruptcy = true;
      }
    },
    validateBankruptcyReason() {
      this.missingBankruptcyReason = this.bankruptcyReason ? false : true;
    },
    dropdownShouldOpen(VueSelect) {
      if (this.otherBankNature !== null) {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
  }
}
</script>


<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

th,
td {
  text-align: left;
}

.loan-or-line {
  outline: 1px dotted $orange;
}

.ll-section {
  text-align: left;
}

.section-title-med {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 2.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1em;
    grid-template-columns: 1fr 1fr;
    text-align: right;
  }
}

.tf-label-banker {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 28px;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
  }
}

.ll-position {
  @include media-breakpoint-down(md) {
    flex: 1;
  }

  @include media-breakpoint-up(md) {}
}

.contact-container {
  background-color: $grayBg;
}

.continue-btn,
.continue-btn {
  background: $yellowLight;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.row3-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }

  >div+div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.ll-address {
  @include media-breakpoint-up(lg) {
    .tf-address {
      min-width: 500px;
      max-width: unset;
    }
  }

  @include media-breakpoint-down(lg) {
    flex: 1;
  }
}

.ll-city {
  @include media-breakpoint-up(xl) {
    .tf-city {
      min-width: 380px;
    }
  }

  @include media-breakpoint-down(xl) {
    flex: 1;
    max-width: unset;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 1em 1em 1em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.row4-container {
  display: grid;
  grid-column-gap: 3em;
  grid-template-columns: 1fr 1fr;
  text-align: left;

}

.row6-container {
  display: grid;
  grid-template-columns: 1fr 60px;
  text-align: left;
}

.toggle-btn-container .icon {
  flex-basis: 32px;
  text-align: center;
  margin-left: auto;
}

.toggle-btn-container .toggle-btn-txt {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  margin-left: auto;
}

.toggle-btn-container-error {
  border: 1px dotted $red;
  padding: 10px;
  margin-left: auto;
}

.toggle-btn-container {
  align-items: stretch;
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 2px;
  row-gap: 1em;
  margin-left: auto;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }

  .form-error-msg {
    width: 100%;
  }
}

.toggle-btn-container>* {
  column-gap: 0.25em;
  display: flex;
  margin-left: auto;

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
}

.toggle-btn-txt-bold {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-up,
.toggle-btn-down {
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: unset;
  min-width: 9em;
  width: calc(50% - 0.6em);
}

.toggle-btn-up {
  background: $white;
  border: 1px solid $grayMid;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);

  &:hover,
  &:focus,
  &:active {
    background: rgba(254, 214, 123, 0.2);
    border: 1px solid $grayDark;
    box-shadow: 0px 3px 0px rgb(80 80 80 / 60%);
  }
}

.toggle-btn-down {
  background: rgba(254, 214, 123, 0.2);
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}

.toggle-btn-up:hover {
  cursor: pointer;
}

.toggle-btn-container .toggle-btn-txt {
  text-align: center;
}

.style {
  box-sizing: border-box; 
  border-radius: 3px;
  --vs-border-color: #B0B0B0;
  --vs-line-height: 2.5;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.ll-select-error-business-nature {
  border: 1px solid $red;
}

.tf-note-business {
  margin-top: 4px;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.yn-question {
  display: block;

  @include media-breakpoint-up(md) {
    display: flex;
  }
  .tf-label {
    flex-basis: 50%;
  }

  .toggle-btn-container {
    flex: 1;

    @include media-breakpoint-down(md) {
      button {
        flex-basis: calc(50% - 0.6em);
      }
    }
  }
  .yes-explain {
    @include media-breakpoint-down(md) {
      margin-top: 1em;
    }
    padding-left: 2em;
    width: 100%;
  }
}

td .ll-textfield-error,
td .ll-textfield-error + .form-error-msg {
  width: 146px;
}

.question-label {
  align-self: center;
  min-width: 55%;
}
>>> {
  --vs-controls-color: #664cc3;
  --vs-border-color: #664cc3;

  --vs-dropdown-bg: #282c34;
  --vs-dropdown-color: #cc99cd;
  --vs-dropdown-option-color: #cc99cd;

  --vs-selected-bg: #664cc3;
  --vs-selected-color: black;
  --vs-search-input-color: #eeeeee;

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #black;
}
</style>